import React from 'react'

const Footer = () => {
    return (
        <div style={{position: 'fixed', bottom: '5px', right: '25px', height: '25px', width: '5px', backgroundColor: {}}}>
            
        </div>
    )
}

export default Footer
